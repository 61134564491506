import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotGoogleCalendarIntegration = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Purpose">New/change of Google Calendar</Descriptions.Item>
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
        <Descriptions.Item label="Identifier">{resource.identifier}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnswerBotGoogleCalendarIntegration;
